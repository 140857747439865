/*
Template Name: Hortzhotel - Admin & Dashboard Template
File: Custom Css File
*/
.w-1440x925 {
  max-width: 1440px;
  height: 925px;
}

.w-1440x676 {
  max-width: 1440px;
  height: 676px;
}

.w-1440x450 {
  max-width: 1440px;
  height: 450px;
}

.w-1440x280 {
  max-width: 1440px;
  height: 280px;
}

.w-1280x480 {
  max-width: 1280px;
  height: 480px;
}

.w-620x620 {
  max-width: 620px;
  height: 620px;
}

.w-578x293 {
  max-width: 578px;
  height: 293px;
}

.w-480x712 {
  max-width: 480px;
  height: 712px;
}

.w-480x710 {
  max-width: 480px;
  height: 710px;
}

.w-388x575 {
  max-width: 388px;
  height: 575px;
}

.w-365x220 {
  max-width: 365px;
  height: 220px;
}

.w-1440x925,
.w-1440x676,
.w-1440x450,
.w-1440x280,
.w-1280x480,
.w-620x620,
.w-578x293,
.w-480x712,
.w-480x710,
.w-388x575,
.w-365x220 {
  width: 100%;
  object-fit: cover;
}

.w-640x280 {
  width: 640px;
  height: 220px;
}

.w-200x100 {
  width: 200px;
  height: 100px;
}